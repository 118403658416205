<template>
  <div class="page-root">


    <!--  导航栏  -->
    <div class="nav-area">
      <div class="nav-left">
        <img src="@/assets/logo/beli-logo.png" />
      </div>
      <div class="nav-right">
        <div class="right-text" @click="toIndex(0)">首页</div>
        <div class="nav-line"></div>
        <div class="right-text" @click="toIndex(1)">解决方案</div>
        <div class="nav-line"></div>
        <div class="right-text" @click="toIndex(2)">定制服务</div>
        <div class="nav-line"></div>
        <div class="right-text" @click="toIndex(3)">配套服务</div>
        <div class="nav-line"></div>
        <div class="right-text" @click="toIndex(4)">关于我们</div>
      </div>
    </div>
    <!--  swiper  -->
    <swiper style="height:100vh;"
            class="mySwiper"
            :options="swiperOption"
            ref="mySwiper"
            v-if="showFlag">
      <swiper-slide class="slide1" style="height:100vh;">
        <div class="slide1-content">
          <div class="slide1-text" id="slide1-text">
            <div class="text1">信任、卓越、领先、智能</div>
            <div class="text2">长期致力于为集团企业、医院、政府及企事业单位提供一站式数字化解决方案</div>
          </div>
<!--          <div class="slide1-btn" @click="showQrCodeBox">咨询合作</div>-->
        </div>
      </swiper-slide>
      <swiper-slide class="slide2" style="height:100vh;">
        <div class="slide2-content">
          <div @mouseover="mouseOverFun(index)"
               :class="['slide2-desc',curActive===index?'slide2-active':'','slide2_'+index]"
               v-for="(item,index) in list2" :key="index"
               :ref="'slide2_'+index"
               :style="{'background-image': `url(${item.pic})`}">
            <div class="inner">
              <div class="desc-title">{{item.title}}</div>
              <div class="desc-main">
                <div v-show="curActive!==index">
                  <div v-for="(item1,index1) in item.content" :key="index1">{{item1}}</div>
                </div>
                <div v-show="curActive===index">
                  <div v-for="(item1,index1) in item.content1" :key="index1">{{item1}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide3" style="height:100vh;">
        <div class="slide3-content">
          <div class="slide3-title">
            <div>定</div><div>制</div><div>服</div><div>务</div>
          </div>
          <div class="slide3-main">
            <div :class="['slide3-list',index===2 || index===3?'slide3-list1':'']"
                 v-for="(item,index) in list3" :key="index">
              <div class="slide3-list-top">
                <img :src="item.pic" />
                <div class="list-top-main" data-swiper-parallax="100">
                  <div>{{item.title}}</div>
                  <div>{{item.subTitle}}</div>
                </div>
              </div>
              <div class="slide3-line"></div>
              <div class="slide3-list-bottom" data-swiper-parallax="-100">
                <div v-for="item1 in item.content" :key="item1">{{item1}}</div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide4" style="height:100vh;">
        <div class="slide4-content">
          <div class="slide4-title">
            <div>配</div><div>套</div><div>服</div><div>务</div>
          </div>
          <div class="slide4-main">
            <div class="slide4-list" v-for="(item,index) in list4" :key="index">
              <div class="slide4-list-img"><img :src="item.pic" /></div>
              <div class="slide4-list-title" data-swiper-parallax="-100">{{item.title}}</div>
              <div class="slide4-list-sub" data-swiper-parallax="-100">{{item.subTitle}}</div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <swiper-slide class="slide5" style="height:100vh;">
        <div class="slide5-content" data-swiper-parallax="-100" data-swiper-parallax-scale="0.15">
          <div class="slide5-title">关于我们</div>
          <div class="slide5-list">
            <div class="list-desc">
              <div>10<span>+</span></div>
              <div>成立时间</div>
            </div>
            <div class="slide5-line"></div>
            <div class="list-desc">
              <div>20<span>+</span></div>
              <div>服务行业</div>
            </div>
            <div class="slide5-line"></div>
            <div class="list-desc">
              <div>30<span>+</span></div>
              <div>团队成员</div>
            </div>
            <div class="slide5-line"></div>
            <div class="list-desc">
              <div>100<span>+</span></div>
              <div>成功案例</div>
            </div>
          </div>
          <div class="slide5-desc">
            <div>福建比利信息科技有限公司成立于2014年，长期致力于为集团型企业、医院、政府等企事业单位提供专业的一站式信息化服务。公司长期做为用友、金蝶、东软、帆软等行业上市公司合作伙伴，承接相关实施与定制开发项目。同时公司自主研发移动互联网应用专利产品，先后荣获福建省科技型企业证书、高新技术企业证书、软件企业证书、软件产品证书，共有30余项软件著作权证书、2项软件产品证书。</div>
            <div>联系电话：18105092390, 邮箱：service@beli-tech.com。</div>
          </div>
          <div class="slide5-contact">
<!--            <div><img src="@/assets/slide5/1.png"/></div>-->
<!--            <div><img src="@/assets/slide5/2.png"/></div>-->
<!--            <div><img src="@/assets/slide5/3.png"/></div>-->
<!--            <div><img src="@/assets/slide5/4.png"/></div>-->
          </div>
          <div class="slide5-copyright">© 福建比利信息科技有限公司 <a class="link-text" target="_blank" href="https://www.beianx.cn/search/beli-tech.com">闽ICP备18016190号-1</a></div>
        </div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>

    <!--  咨询合作弹框   先没用  -->
    <div class="qr-code-box" v-if="qrCodeFlag">
      <div class="close-icon" @click="closeBox">
        <img src="@/assets/slide1/close.png" />
      </div>
      <div class="box-title">联系我们</div>
      <div class="box-content">
        <div>
          <div><img src="@/assets/slide1/qr-code.jpeg"/></div>
          <div class="box-content-text">团队微信号1</div>
        </div>
        <div>
          <div><img src="@/assets/slide1/qr-code.jpeg"/></div>
          <div class="box-content-text">团队微信号2</div>
        </div>
      </div>
    </div>
    <!--  咨询合作弹框 结束  -->


  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: "indexPage",
  components: {
    Swiper,
    SwiperSlide,
  },
  data(){
    return {
      showFlag: true,
      // swiper option
      swiperOption: {
        observer: true,
        observeParents: true,
        observeSlideChildren:true,
        mousewheel: true,
        updateOnWindowResize: true,
        autoHeight:true,
        speed: 800,
        pagination: {
          el: '.swiper-pagination',
          clickable :true
        },
        parallax : true,
        direction:"vertical",
        effect: 'none',
        fadeEffect: {
          crossFade: true,
        },
        on:{
          slideChange: function(){
            if(this.activeIndex==0){
              document.getElementById("slide1-text").classList.add("slide1-text-ani");
              // document.getElementById("slide1-text").style.display = 'block'
            }else{
              document.getElementById("slide1-text").classList.remove("slide1-text-ani");
              // document.getElementById("slide1-text").style.display = 'none'
            }

          },
        },
      },

      // slide1 box
      qrCodeFlag: false,
      activeIndex: 0,

      // slide2
      curActive: 0,
      list2: [
        {
          pic: require('@/assets/slide2/1.png'),
          title: '集团企业',
          content: ['供应链解决方案','人力资源解决方案','生产制造解决方案','生产成本解决方案','企业应用集成解决方案','企业数字化整体解决方案'],
          content1: ['供应链解决方案（采购管理、销售管理、库存管理）、人力资源解决方案（考勤管理、绩效管理、360人才测评、培训管理、在线学习平台）、生产制造解决方案（生产计划管理、现场执行管理、品质管理、设备管理）、生产成本解决方案、企业应用集成解决方案（企业内门户、单点登录解决方案、数据中台解决方案）、企业数字化整体解决方案（大数据分析、数字大屏、人工智能）。'],
        },
        {
          pic: require('@/assets/slide2/2.png'),
          title: '医疗行业',
          content: ['HRP解决方案','SPD解决方案','供应商管理解决方案','数据中台解决方案','健康管理APP解决方案','互联网医院解决方案'],
          content1: ['HRP解决方案、SPD解决方案、供应商管理解决方案、数据中台解决方案、健康管理APP解决方案、互联网医院解决方案。']
        },
        {
          pic: require('@/assets/slide2/3.jpg'),
          title: '企事业单位',
          content: ['交通行业综合业务管理解决方案','交通行业安全监管解决方案','道路治理解决方案','网约车监管解决方案','危废管理解决方案','后勤保障ToB电商平台解决方案','运量统计解决方案','绩效考核管理决方案'],
          content1: ['HRP解决方案、SPD解决方案、供应商管理解决方案、数据中台解决方案、健康管理APP解决方案、互联网医院解决方案。']
        },
        {
          pic: require('@/assets/slide2/4.png'),
          title: '政府',
          content: ['政务行政审批解决方案','数据汇聚共享解决方案','协同办公解决方案','邮箱系统解决方案','资产管理解决方案','网站群管理解决方案'],
          content1: ['政务行政审批解决方案、数据汇聚共享解决方案、协同办公解决方案、邮箱系统解决方案、资产管理解决方案、网站群管理解决方案。']
        }
      ],

      // slide3,4
      list3: [
        {
          pic: require('@/assets/slide3/1.png'),
          title: '平台服务定制',
          subTitle: '业务融合 / 流程再造',
          content: ['ERP/MES/OA/CRM/SCM等业务管理系统定制','企业数据中台','大数据分析平台','人工智能服务平台']
        }, {
          pic: require('@/assets/slide3/2.png'),
          title: '标准产品',
          subTitle: '多平台定制,满足行业需求',
          content: ['UAP/NC/NCC/U8用友平台定制功能实施及开发','K3BOS\\云星空金蝶平台定制功能实施及开发',
            'UNIEAP东软平台定制功能开发','FINEREPORT\\FINEBI帆软平台定制功能实施及开发']
        },{
          pic: require('@/assets/slide3/3.png'),
          title: '移动应用',
          subTitle: '更懂APP / 混合应用',
          content: ['APP定制','平板定制','小程序定制','微信公众号定制']
        },{
          pic: require('@/assets/slide3/4.png'),
          title: '品牌服务',
          subTitle: '从策划到设计,再到开发/一站式服务',
          content: ['LOGO设计','品牌视觉系统','官网建设','企划服务']
        },
      ],
      list4: [
        {
          pic: require('@/assets/slide4/1.png'),
          title: 'IT基础建设服务',
          subTitle: '机房设计/建设、超融合服务器采购搭建、阿里云/腾讯云/华为云/天翼云专业采购、局域网设计组网、信息化安全体系建设。'
        },
        {
          pic: require('@/assets/slide4/2.png'),
          title: '资质认证服务',
          subTitle: '微信服务开通、电子支付服务开通、企业相关认证代理(高薪、双软、ISO、软著、专利等)。'
        },
        {
          pic: require('@/assets/slide4/3.png'),
          title: 'IT咨询',
          subTitle: 'IT规划、IT应用及优化、IT项目管理等。'
        }
      ]

    }
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper
    }
  },
  created() {
    this.showFlag = false
  },
  mounted() {

    this.showFlag = true
    // this.$nextTick(()=>{
    //   this.swiper.update();
    // })

  },
  methods:{
    // showQrCodeBox(){
    //   this.qrCodeFlag = true
    // },
    // closeBox(){
    //   this.qrCodeFlag = false
    // },
    toIndex(index){
      this.swiper.slideTo(index, 1000, false)
    },
    mouseOverFun(index){
      this.curActive = index
      if(index===0){
        this.$refs.slide2_0[0].style.left = '0%'
        this.$refs.slide2_1[0].style.left = '40%'
        this.$refs.slide2_2[0].style.left = '60%'
        this.$refs.slide2_3[0].style.left = '80%'
      }else if(index===1){
        this.$refs.slide2_0[0].style.left = '0%'
        this.$refs.slide2_1[0].style.left = '20%'
        this.$refs.slide2_2[0].style.left = '60%'
        this.$refs.slide2_3[0].style.left = '80%'
      }else if(index===2){
        this.$refs.slide2_0[0].style.left = '0%'
        this.$refs.slide2_1[0].style.left = '20%'
        this.$refs.slide2_2[0].style.left = '40%'
        this.$refs.slide2_3[0].style.left = '80%'
      }else if(index===3){
        this.$refs.slide2_0[0].style.left = '0%'
        this.$refs.slide2_1[0].style.left = '20%'
        this.$refs.slide2_2[0].style.left = '40%'
        this.$refs.slide2_3[0].style.left = '60%'
      }
      this.$refs['slide2_'+index][0].style.transition = 'left 1s'
    }
  }

}
</script>

<style scoped lang="scss">
.page-root{
  width: 100vw;
  height: 100vh;
  position: relative;
}

/*  swiper  */
.swiper,.mySwiper {
  width: 100vw;
  height: 100vh;
}
.swiper-container {
  width: 100vw;
  height: 100vh;
}
.swiper-slide {
  width: 100vw;
  height: 100vh!important;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  //transition: 300ms;
  //transform: scale(0.5);
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
  //background-color: aqua;
  //transform: scale(1);
}
/* 重写指示点  */
::v-deep .swiper-container-vertical .swiper-pagination-bullets{
  right: 45px!important;
}
::v-deep .swiper-container-vertical .swiper-pagination-bullets .swiper-pagination-bullet{
  background: #fff!important;
  z-index: 999999;
  margin: 50px 0;
  display: block;
  width: 8px;
  height: 8px;
  opacity: 1;
  border-radius: 50%;
}
::v-deep .swiper-container-vertical .swiper-pagination-bullets .swiper-pagination-bullet-active{
  outline: 1px solid #fff;
  outline-offset: 20px;
  border-radius: 50%;
}
//::v-deep .swiper-container-vertical .swiper-pagination-bullets .swiper-pagination-bullet-active:last-child{
//  background: transparent!important;
//  position: relative;
//}
//::v-deep .swiper-container-vertical .swiper-pagination-bullets .swiper-pagination-bullet-active:last-child:before{
//  position: absolute;left: -0.4vh;
//  content: '';
//  display: inline-block;
//  width: 1.4vh;height: 1.4vh;
//  border-left: 2px solid #fff;border-top: 2px solid #fff;
//  transform: rotate(-314deg);
//}

/*  头部  */
.nav-area{
  width: 100%;height: 8vh;
  background: rgba(51,51,51, 0.1);
  position: absolute;top: 0;left: 0;
  display: flex;justify-content: space-between;align-items: center;
  z-index: 999;
  padding: 0 4vw 0 2vw;box-sizing: border-box;
  .nav-left{
    img{
      transform: scale(0.8);
    }
  }
  .nav-right{
    display: flex;align-items: center;
    .right-text{
      color: #fff;
      cursor: pointer;
      font-size: 2vh;
    }
    .nav-line{
      height: 2.6vh;width: 1px;
      background-color: rgba(255,255,255,0.6);
      margin: 0 1.8vw;
    }
  }
}

/*  背景及每一项  */
/*  slide1  */
.slide1{
  background-image: url("~@/assets/bg/1.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  color: #fff;
  //height: 100vh!important;
  .slide1-content{
    width: 100%;
    .slide1-text{
      //animation: showAction 0.5s;
      transition: all 1s;
      .text1{
        font-size: 8vh;margin-bottom: 1.6vh;font-weight: bold;
      }
      .text2{
        font-size: 2vh;
      }
    }
    .slide1-text-ani{
      animation: showAction 0.5s;
    }

    .slide1-btn{
      width: 14vw;
      height: 8vh;
      background: linear-gradient(-90deg, #F9CF91, #F7A64C);
      box-shadow: 0px 6px 0px 0px #8C5612;
      border-radius: 48px;
      margin: 18vh auto 0;
      display: flex;justify-content: center;align-items: center;
      font-size: 2.8vh;
      font-weight: bold;
      cursor: pointer;
    }
  }
}

/*  咨询合作弹框  */
.qr-code-box{
  width: 36vw;height: 46vh;background-color: #fff;
  position: absolute;left: 50%;top: 50%;
  margin-left: -18vw;margin-top: -23vh;
  z-index: 99999;
  animation: showAction 1s;
  .close-icon{
    position: absolute;right: 1.2vw;top: 1.2vw;
    cursor: pointer;
    img{
      width: 2.4vh;height: 2.4vh;
    }
  }
  .box-title{
    height: 12vh;line-height: 12vh;text-align: center;
    font-size: 3vh;font-weight: bold;
  }
  .box-content{
    display: flex;justify-content: space-around;
    padding: 0 2vw;box-sizing: border-box;
    img{
      width: 24vh;height: 24vh;
    }
    .box-content-text{
      color: #999;text-align: center;
      margin-top: 2.5vh;
    }
  }
}
@keyframes showAction{
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}


/*  slide2  */
.slide2{
  //height: 100vh!important;
  .slide2-content{
    //display: flex;flex-shrink: 0;
    width: 120%;height: 100%;
    position: relative;
    .slide2-desc{
      color: #fff;
      background-repeat: no-repeat;background-size: 100% 100%;
      width: 40%;height: 100vh;
      //display: flex;align-items: flex-start;
      //flex-direction: column;justify-content: flex-end;flex-shrink: 0;
      padding-left: 2%;box-sizing: border-box;
      position: absolute;top: 0;
      .inner{
        height: 92vh;width: 100%;
        padding-top: 40vh;box-sizing: border-box;
        z-index: 999!important;
        text-align: left;cursor: pointer;
        transition: all 1s;
        .desc-title{
          font-size: 4vh;font-weight: bold;
          margin-bottom: 1.5vh;
        }
        .desc-main{
          font-size: 2vh;
          >div{
            margin-bottom: 0.8vh;
          }
        }
      }
    }
    .slide2-desc:before{
      content: '';
      display: block;
      position: absolute;left: 0;
      width: 100%;height: 100%;
      background-color: rgba(#282D41,0.8);
      z-index: -1;
      transition: all 1s;
    }
    .slide2-active{
      flex: 1 1 40%;padding-right: 2%!important;
      transition: all 1s;
      .inner{
        padding-top: 65vh!important;
        transition: all 1s;
      }
    }
    .slide2-active:before{
      background: linear-gradient(0deg, rgba(#282D41,0.9), rgba(#fff,0));
    }
    .slide2_0{
      z-index: 100;left: 0;top: 0;
    }
    .slide2_1{
      z-index: 101;left: 40vw;top: 0;
    }
    .slide2_2{
      z-index: 102;left: 60vw;top: 0;
    }
    .slide2_3{
      z-index: 103;left: 80vw;top: 0;
    }
  }
}

/*  slide3,4  */
.slide3,.slide4{
  //height: 100vh!important;
  background-image: url("~@/assets/bg/3-4.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.slide3{
  .slide3-content{
    color: #fff;
    width: 100%;
    display: flex;justify-content: center;
    margin-top: 10vh;
    .slide3-title{
      margin-right: 20px;
      >div{
        font-size: 4vh;font-weight: 900;
        line-height: 4.3vh;
      }
    }
    .slide3-main{
      display: flex;flex-wrap: wrap;
      width: 64vw;
      margin-top: 6vh;
      .slide3-list{
        width: 30vw;height: 30vh;
        background: linear-gradient(180deg, #419AF3, #2959CE);
        border: 1px solid #15D7EE;
        margin-right: 2vw;margin-bottom: 2vw;
        padding: 1.2vw 1.5vw;box-sizing: border-box;
        display: flex;flex-direction: column;
        .slide3-list-top{
          display: flex;
          img{
            width: 9vh;height: 9vh;
          }
          .list-top-main{
            >div{
              text-align: left;
            }
            >div:first-child{
              font-size: 2.6vh;margin-top: 1vh;
            }
            >div:last-child{
              font-size: 1.5vh;margin-top: 1vh;
            }
          }
        }
        .slide3-line{
          width: 100%;height: 1px;
          background: linear-gradient(90deg, #FFFFFF, #3983E6);
          opacity: 0.2;
          margin-bottom: 1.5vh;
        }
        .slide3-list-bottom{
          flex: 1;
          display: flex;flex-direction: column;justify-content: space-between;
          >div{
            text-align: left;
            position: relative;
            padding-left: 15px;
            font-size: 1.8vh;
          }
          >div:before{
            content: '';
            display: block;
            width: 6px;height: 6px;background-color: #fff;
            position: absolute;left: 0;top: 50%;margin-top: -3px;
            border-radius: 50%;
          }
        }
      }
      .slide3-list:nth-child(2n){
        margin-right: 0!important;
      }
      .slide3-list1{
        height: 22vh;
        .slide3-list-bottom{
          flex: 1;
          width: 80%;
          display: flex;flex-direction: row;flex-wrap: wrap;
          justify-content: flex-start;
          >div{
            width: 40%;
          }

        }
      }
    }
  }
}
.slide4{
  .slide4-content{
    color: #fff;
    width: 100%;
    display: flex;justify-content: center;
    margin-top: 10vh;
    .slide4-title{
      margin-right: 20px;margin-top: -6vh;
      >div{
        font-size: 4vh;font-weight: 900;
        line-height: 4.3vh;
      }
    }
    .slide4-main{
      display: flex;justify-content: space-between;
      width: 65vw;
      margin-top: 6vh;
      .slide4-list{
        width: 20vw;height: 45vh;
        background: linear-gradient(-90deg, #419AF3, #2959CE);
        border: 1px solid #15D7EE;
        display: flex;align-items: center;flex-direction: column;
        padding: 2vw;box-sizing: border-box;
        .slide4-list-img{
          height: 30%;
        }
        .slide4-list-title{
          font-size: 3vh;margin: 2vh 0 3vh;
        }
        .slide4-list-sub{
          font-size: 2vh;
        }
      }
    }
  }
}
/*  slide5  */
.slide5{
  background-image: url("~@/assets/bg/5.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  //height: 100vh!important;
  .slide5-content{
    color: #fff;padding: 0 15vw;box-sizing: border-box;
    .slide5-title{
      height: 40vh;line-height: 57vh;
      font-size: 5vh;font-weight: bold;font-family: Microsoft YaHei;
    }
    .slide5-list{
      display: flex;align-items: center;justify-content: center;
      height: 20vh;width: 100%;
      >div{width: 25%;}
      .list-desc{
        >div:first-child{
          font-size: 9vh;position: relative;font-weight: bold;
          span{
            position: absolute;top: -1.5vh;font-size: 6vh;
          }
        }
        >div:last-child{font-size: 2.4vh;font-family: Microsoft YaHei;}
      }
      .slide5-line{
        width: 2px;
        height: 75%;
        background: linear-gradient(180deg, rgba(255,255,255,0),rgba(255,255,255,1), rgba(46,102,213,0));
      }
    }
    .slide5-desc{
      height: 25vh;line-height: 3.5vh;
      padding-top: 2.5vh;box-sizing: border-box;
      font-size: 1.8vh;font-family: Microsoft YaHei;
      text-align: left;
    }
    .slide5-contact{
      height: 12vh;
      display: flex;align-items: center;justify-content: center;
      >div{
        cursor: pointer;
        width: 6vh;height: 6vh;background-color: rgba(255,255,255,0.5);
        border-radius: 50%;
        display: flex;justify-content: center;align-items: center;
        margin: 0 0.8vw;
        img{
          width: 5vh;height: 5vh;
        }
      }
    }
    .slide5-copyright{
      height: 10vh;font-size: 1.6vh;font-family: Microsoft YaHei;
      padding-top: 1vh;box-sizing: border-box;
      .link-text{
        color: #fff;text-decoration: none;
      }
    }
  }
}
</style>
