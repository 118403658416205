<template>
  <div id="app">
    <Home />
  </div>
</template>

<script>
import Home from './views/home/index.vue'

export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
body{
  margin: 0;padding: 0;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
